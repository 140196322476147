<template>
    <div class="viewer">
        <v-row align="center" v-if="recipe != null">
            <v-col align="center" style="font-size: 40pt; font-family: logoFont">
                דרג את: {{ recipe.name }}
            </v-col>
        </v-row>
        <v-row align="center" style="height: 30px"></v-row>
        <v-row align="center">
            <v-col cols="12" align="center">
                <qrcode :value="'http://htcooking.com/Recipe/' + recipeId" :options="{ width: 350 }"></qrcode>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="12" align="center" style="font-size: 34pt; font-family: logoFont">
                ציון נוכחי: {{ score }}<br>
                כמות מדרגים: {{ count }}
            </v-col>
        </v-row>        
    </div>
</template>
                                   
<script>
import { mapGetters } from 'vuex';

export default {
    name: "Viewer",
    mounted() {
        var self = this;
        this.$vuetify.rtl = true;
        this.recipeId = this.$route.params.id;
        this.$nextTick(() => {
            self.getRating();
            self.getRecipe();
            setInterval(function() {
                self.getRating();
            }, 5000);
        });
        
    },    
    data() {
        return {
            recipeId: "",
            score: 1,
            count: 0,
            recipe: null
        }
    },
    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain",
        }),
    },
    methods: {
        getRating() {
            var self = this;
            
            var options = {
                url:  `${this.currentDomain}/GET_RECIPE_RATING`,
                method: 'GET',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                params: { recipe: this.$route.params.id }
            };
            console.log(this.$route.params.id);
            this.$http(options).then((response) => {            
                if (response.status == 200) {
                    self.score = response.body.score;
                    self.count = response.body.count;
                } else {
                    self.notFound = true;
                    console.error("Cannot get recipe");
                }
            });
        },
        getRecipe() {
            var self = this;
            
            var options = {
                url:  `${this.currentDomain}/GET_RECIPE`,
                method: 'GET',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                params: { id: this.$route.params.id }
            };
            console.log(this.$route.params.id);
            this.$http(options).then((response) => {            
                if (response.status == 200) {
                    self.recipe = response.body;
                } else {
                    console.error("Cannot get recipe");
                }
            });
        },           
    }
}
</script>